<script setup>
import { useSettingsStore } from '../../../../stores/settings.store';
import { ref } from 'vue';
const settingsStore = useSettingsStore();
const socialMedia = ref({});
const settings = settingsStore.settingsGetter();
socialMedia.value = {
	facebook: settings.social_media_facebook,
	twitter: settings.social_media_twitter,
	linkedin: settings.social_media_linkedin,
	youtube: settings.social_media_youtube,
}
</script>
<template>
	<ul class="flex justify-center mt-5 space-x-5">
		<li>
			<NuxtLink :to="socialMedia.facebook" target="_blank" aria-label="Facebook" class="text-white hover:text-gray-100">
				<i :class="`fab fa-facebook`"></i>
				<span class="hidden">
					Facebook
				</span>
			</NuxtLink>
		</li>
		<li>
			<NuxtLink :to="socialMedia.twitter" target="_blank" aria-label="Twitter" class="text-white hover:text-gray-100">
				<i :class="`fab fa-twitter`"></i>
				<span class="hidden">
					Twitter
				</span>
			</NuxtLink>
		</li>
		<li>
			<NuxtLink :to="socialMedia.linkedin" target="_blank" aria-label="Linkedin" class="text-white hover:text-gray-100">
				<i :class="`fab fa-linkedin`"></i>
				<span class="hidden">
					Linkedin
				</span>
			</NuxtLink>
		</li>
		<li>
			<NuxtLink :to="socialMedia.youtube" target="_blank" aria-label="Youtube" class="text-white hover:text-gray-100">
				<i :class="`fab fa-youtube`"></i>
				<span class="hidden">
					Youtube
				</span>
			</NuxtLink>
		</li>
	</ul>
</template>
